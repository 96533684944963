// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBJWB-hlXDoj9CVctEOf9bv9qMuQqx-MUg",
  authDomain: "push-notif-facee.firebaseapp.com",
  projectId: "push-notif-facee",
  storageBucket: "push-notif-facee.appspot.com",
  messagingSenderId: "870674136614",
  appId: "1:870674136614:web:e37e14835bd74b2618f0e7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const requestPermission = () => {
  console.log("Requesting User Permission......");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return getToken(messaging, {
        vapidKey: `BPqSA8O3SLLQxtFiHgry7qeYu_EGUGJRA5SRzF4L5vqQdWraseEQoHjybfzafP8JK9YhBrp-eR6R84OChJOgsS8`,
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Client Token: ", currentToken);
          } else {
            console.log("Failed to generate the app registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      console.log("User Permission Denied.");
    }
  });
};

requestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
