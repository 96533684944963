import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { requestPermission, onMessageListener, messaging } from "./firebase";
import { getToken } from "firebase/messaging";
// import Notification from './notifucation';

function App() {
  const [token, setToken] = useState("");
  useEffect(() => {
    requestPermission();
    const unsubscribe = onMessageListener().then((payload) => {
      new Notification(payload?.notification?.title, {
        body: payload?.notification?.body,
        vibrate: [200, 100, 200]
      });
    });
    return () => {
      unsubscribe.catch((err) => console.log("failed: ", err));
    };
  }, []);

  useEffect(() => {
    getToken(messaging).then((token) => {
      setToken(token);
    });
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        {/* <Notification /> */}
        <div className="card">{token}</div>
      </header>
    </div>
  );
}

export default App;
